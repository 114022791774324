.navbar{
    height:56px;
    background-color: #00205b;
    display: flex;
}

.logoContainer{
    width: 60%;
    display: flex;
    align-items: center;
    margin-left: 5%;
}

.navbarButtonContainer{
    display: flex;
    width: 40%;
    justify-content: space-around;
    align-items: center;
}

.links{
    color: black;
    text-decoration: none;
    color: #ffffff;
    font-family: "Roboto";
}

.userAvatair{
    background-color: #00aec7;
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #ffffff;
}

.clicked{
    border-bottom: 5px solid white;
    line-height: 50px;
}