.toolbar {
  background-color: #f5f5f5;
  height: 50px;
  padding: 10px;
}

.container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 10px;
  padding: 10px;
}
