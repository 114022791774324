.historyItemList {
  overflow: auto;
  height: 80vh;
}

.historyItemCard {
  margin: 10px;
}

.userHistoryCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}
