.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.buttons {
  display: flex;
  gap: 10px;
}

.submitCard {
  background-color: lightgreen;
  padding: 10px;
}

.questionBody {
  background-color: #ffffff;
  border-radius: 9px;
  padding: 25px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 1em;
  border-left: 6px solid #0077ff;
}
