.userProfileCard {
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}

.userImage {
  overflow: hidden;
}

.userImage img {
  height: 20%;
  width: 100%;
  object-fit: cover;
}
.userInfo {
  padding: 20px;
}

.empty-container {
  min-height: 200px;
}
