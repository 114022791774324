.container{
    margin: 3em;
}

.qrContainer{
    margin: auto;
    height: 10em;
    width: 10em;
}

.textBox{
    width: 100%;
    border: 1px solid #c7c7c7;
    height: 2em;
}

.optionContainer{
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    align-items: center;
}
.optionRow{
    margin-bottom: 2em;
    width: 80%;
    justify-content: space-between;
    display: flex;
}
.optionTextBox{
    width: 60%;
}