body{
    background-color: #f0ebf8;
    
}

.submitButton {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.h3{
    margin: 2em;
}
.containerQuestion{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
}
.questionContainer{
    background-color: #ffffff;
    border-radius: 9px;
    padding: 25px 15px 15px 15px;
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 1em;
    border-left: 6px solid #0077FF;
}

.questionContainerHeader{
    max-width: 100%;
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* .textBox{
    height: 2.5em;
    width: 60%;
    border: 0px;
    padding: 0.3em;
    font-size: 0.9em;
} */

.selectOptionType{
    width: 23%;
    padding: 0.8em;
    font-size: 0.8em;
    border-color: #c5c5c5;
    border-radius: 10px;
    margin-right: 3%;
}

.selectQuestionType{
    width: 12em;
    padding: 0.8em;
    margin-right: 2em;
    font-size: 0.8em;
}

.questionContainerBody{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0.7em;
    align-items: center;
}
.optionContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    align-items: center;
}

.addOptionBtn{
    margin-top: 1em;
    margin-bottom: 1em;
    border: 0px;
    font-size: 0.8em;
    cursor: pointer;
    border-radius: 5px;
    padding: 6px;
    background-color: #c5c5c5;
    color: #ffffff;

}

.addOptionBtn:hover{
    background-color: #9f9f9f;
}

.questionFooter{
    display: flex;
    flex-direction: row;
    justify-content: end;

}

.addQuestionBtn{
    border: 0px;
    background-color: #ffffff;
    font-size: 1em;
    cursor: pointer;
    padding: 0.3em;
    margin-right: 0.7em;
}


.removeBtn{
    border: 0px;
    background-color: #ffffff;
    font-size: 1em;
    cursor: pointer;
    text-align: end;
    padding: 0.3em;
}


.saperatorLine{
    width: 100%;
    height: 1px;
    background-color: #9f9f9f;
    margin-top: 0.5em;
}

.optionTextContainer{
    width: 30em;
}

.deleteIcon{
    color: #9f9f9f;
}

.addIcon{
    color: #9f9f9f;
}

.addIcon:hover{
    color: rgb(95, 175, 4);
}

.optionLabel{
    font-size: 0.88em;
}



.customerFileBtn {
  width: 5%;
  text-align: center;
}

/* .customerFileBtn :hover{
    background-color: #b1b1b1;
    border-radius: 50%;
    padding: 0.3em;
} */

label.label input[type="file"] {
  position: absolute;
  top: -1000px;
}
.label{
    cursor: pointer;
}



.inp {
  position: relative;
width: 60%;
border-radius: 3px;
overflow: hidden;
}
.inp .label {
  position: absolute;
  top: 20px;
  left: 12px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  transform-origin: 0 0;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
  pointer-events: none;
}
.inp .focus-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: -1;
  transform: scaleX(0);
  transform-origin: left;
}
.inp input {
  appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 0px 12px 0 12px;
    height: 35px;
    background: rgba(0, 0, 0, 0.02);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.3);
    color: #000;
    transition: all 0.15s ease;
}
.inp input:hover {
  background: rgba(0, 0, 0, 0.04);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.5);
}
.inp input:not(:-moz-placeholder-shown) + .label {
  color: rgba(0, 0, 0, 0.5);
  transform: translate3d(0, -12px, 0) scale(0.75);
}
.inp input:not(:-ms-input-placeholder) + .label {
  color: rgba(0, 0, 0, 0.5);
  transform: translate3d(0, -12px, 0) scale(0.75);
}
.inp input:not(:placeholder-shown) + .label {
  color: rgba(0, 0, 0, 0.5);
  transform: translate3d(0, -12px, 0) scale(0.75);
}
.inp input:focus {
  background: rgba(0, 0, 0, 0.05);
  outline: none;
  box-shadow: inset 0 -2px 0 #0077FF;
}
.inp input:focus + .label {
  color: #0077FF;
  transform: translate3d(0, -12px, 0) scale(0.75);
}
.inp input:focus + .label + .focus-bg {
  transform: scaleX(1);
  transition: all 0.1s ease;
}


.imageIcon,
.deleteIcon,
.addIcon{
    color: #9f9f9f;
    font-size: 1.1em;
}

.imageIcon{
    font-size: 1.2em;
}


.addIcon:hover{
    color: rgb(95, 175, 4);
}

.deleteIcon:hover{
    color: #fab700;
}

.imageIcon:hover{
    color: #00aec7;
}
.verticalSeperator{
    background-color: #9f9f9f;
    width: 1.2px;
    margin-left: 1em;
    margin-right: 1em;
}